.ProductViewBtn {
    appearance: none;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1.1rem 0 0 -1.1rem;
    border: none;
    cursor: pointer;
    text-indent: -9999em;
    opacity: 0;
    transition: opacity 400ms ease-in;
}
.ProductViewBtn svg {
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -9px 0 0 -9px;
}