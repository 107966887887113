* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
    font-family: 'SF Pro Display', 'SF Pro Icons', 'AOS Icons', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #111;
    background-color: #fff;
}

input {
   font-family: 'SF Pro Display', 'SF Pro Icons', 'AOS Icons', 'Helvetica Neue', Helvetica, Arial, sans-serif;
   font-size: 1rem; 
}

#app {
    padding: 0 1rem;
}

.sr {
    position: absolute;
    top: -9999em;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

@media screen and (min-width: 768px) {
    #root {
      display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: 100vh;
    }
}    
