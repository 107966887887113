.AppHeader {
    padding: 1rem;
    background-color: #eee;
}

.AppHeader .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    padding: 0 1rem;
}

.AppHeader .logo a {
    font-weight: bold;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .1rem;
}

@media screen and (min-width: 768px) {
    .AppHeader .container {
        max-width: 1280px;
    }
}