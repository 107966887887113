.Login {
    margin: 2.5rem auto;
    padding: 0 1rem;
    max-width: 1280px;
}

.Login form div {
    margin-bottom: 1rem;
}

.Login form input {
    display: block;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 4px;
}

.Login form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    text-transform: lowercase;
}

.Login form button[type=submit] {
    appearance: none;
    cursor: pointer;
    display: block;
    width: 100%;
    border: none;
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 1rem 2.5rem;
}

.LoginError {
    font-weight: bold;
    color: #d00;
}

@media screen and (min-width: 768px) {
    .Login {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        max-width: 600px;
    }
    .Login form {
        width: 100%;
    }
}