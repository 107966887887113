.Products {
    margin: 2.5rem auto;
    max-width: 1280px;
}

@media screen and (min-width: 768px) {
    .Products {
        display: grid;
        grid-template-columns: 33.3333% 33.3333% 33.3333%;
        gap: 1rem;
    }
}    