.ProductView {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 100%;
    height: 100vh;
    background-color: rgba(192, 192, 192, 0.5);
    padding: 5% 0;
    transition: opacity 400ms ease-in;
    opacity: 0;
    display: none;
}

.ProductView.visible {
    display: block;
    opacity: 1;
}

.ProductViewContent {
    background-color: #fff;
    margin: 0 auto;
    max-width: 1280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ProductViewContent img {
    max-height: 100%;
    display: block;
    max-width: 100%;
}

.ProductViewClose {
    font-size:  1.6rem;
    appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #000;
    position: absolute;
    top: 1rem;
    right: 1rem;
}