.SingleProduct {
    margin: 2.5rem auto;
    max-width: 1280px;
}

.SingleProduct h1 {
    font-weight: normal;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.SingleProduct p {
    margin: 1rem 0;
}

.SingleProductInfo div {
    font-weight: bold;
}

.SingleProductImage img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
}

.SingleProductAdd {
    cursor: pointer;
    appearance: none;
    border: none;
    padding: 1rem 2.5rem;
    background-color: #000;
    color: #fff;
}

@media screen and (min-width: 768px) {
    .SingleProduct {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .SingleProductInfo {
        order: 2;
        width: 60%;
        padding-left: 2%;
    }
    .SingleProductImage {
        order: 1;
        width: 34%;
    }
}