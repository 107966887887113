.Search {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}

.SearchOverlay {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(192, 192, 192, 0.5);
    opacity: 0;
    display: none;
    transition: opacity 400ms ease-in;
}

.SearchOverlay.visible {
    display: block;
    opacity: 1;
}

.SearchForm {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    position: relative;
    z-index: 1000;
}

.SearchForm .fields-wrap {
    width: 100%;
    
}

.SearchForm .term-wrap {
    position: relative;
    display: block;
    width: 100%;
    z-index: 100;
}

.SearchCancel {
    cursor: pointer;
    appearance: none;
    border: none;
    width: 23px;
    height: 23px;
    color: #fff;
    background-color: #666;
    border-radius: 50%;
    font-size: 22px;
    line-height: 19px;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 5%;
    margin-top: -12px;
    transition: opacity 400ms ease-in;
    opacity: 0;
    display: none;
}

#term {
    appearance: none;
    border-radius: 0.5rem;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 1rem 2.67rem;
    font-size: 1.2rem;
    transition: all 400ms ease-in;
    width: 100%;
    background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30px' height='30px'%3E%3Cpath d='M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: 2% 50%;
    display: block;
}

#term:focus {
    outline-style: none;
    border-color: #0071e3;
    box-shadow: 0 0 0 4px rgba(0, 125, 250, 60%);
}

.SearchSuggestions {
    position: absolute;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 10%);
    width: 100%;
    font-size: 0.9rem;
    padding: 1rem 0;
    z-index: 90;
    display: none;
}

.SearchSuggestions.visible {
    display: block;
}

.SearchSuggestions .suggestion {
    padding: 0.3rem 2.67rem;
    font-weight: 300;
    margin-bottom: 0.6em;
    cursor: pointer;
}

.SearchSuggestions .suggestion:hover {
    background-color: #f5f5f5;
}

.SearchSuggestions .suggestion span {
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30px' height='30px'%3E%3Cpath d='M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 1em 1em;
    padding-left: 1.5em;
}

@media screen and (min-width: 768px) {
    .SearchForm .fields-wrap,
    .SearchSuggestions {
        width: 490px;
    }
}