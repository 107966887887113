.CartPage {
    margin: 2.5rem auto;
    padding: 0 1rem;
    max-width: 1280px;
}

.CartPage table,
.CartPage thead,
.CartPage tbody,
.CartPage td,
.CartPage tr,
.CartPage th {
    display: block;
    text-align: left;
}

.CartPage tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.CartPage thead tr th,
.CartPage tbody tr td {
    width: 25%;
    text-align: center;
}

.CartPage thead tr {
    background-color: #000;
    color: #fff;
    padding: 1rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.CartPage tbody tr {
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0.6rem;
}

.CartPage tbody > tr:last-child {
    border-bottom: none;
    padding: 1rem 0;
    justify-content: space-between;
}

.CartPage tbody > tr:last-child > td:first-child {
    text-align: left;
}

.CartPage tbody > tr:last-child td + td {
    text-align: right;
}

.CartRemove {
    cursor: pointer;
    appearance: none;
    background: transparent;
    color: #c00;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
}

.CartQty,
.CartQty:focus {
    display: block;
    width: 100%;
    border: none;
    appearance: none;
    text-align: center;
    outline-style: none;
}

.CartEmpty {
    cursor: pointer;
    appearance: none;
    border: none;
    padding: 1rem 2.5rem;
    background-color: #c00;
    color: #fff;
}

