.Navigation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.Navigation a {
    color: #000;
    text-decoration: none;
    margin-right: 0.7rem;
}

.Navigation > a:last-child {
    margin-right: 0;
}

.SearchLink,
.CartLink {
    display: block;
    width: 20px;
    height: 20px;
    text-indent: -9999em;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 20px 20px;
}