.ProductListItem {
    margin-bottom: 1rem;    
}

.ProductListItem img {
    display: block;
    max-width: 100%;
    height: auto;
}

@media screen and (min-width: 768px) {

    .ProductListItem {
        margin-bottom: 0;
        position: relative;
    }

    .ProductListItem:hover .ProductViewBtn {
        opacity: 1;
    }

    .ProductListItem img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}