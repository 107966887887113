.ProductForm {
    margin: 2rem 0;
}

.ProductForm div {
    margin-bottom: 1rem;
}

.ProductForm input,
.ProductForm textarea {
    display: block;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 4px;
}

.ProductForm textarea {
    height: 200px;
}

.ProductForm label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    text-transform: lowercase;
}

.ProductForm button[type=submit] {
    appearance: none;
    cursor: pointer;
    display: block;
    width: 100%;
    border: none;
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 1rem 2.5rem;
}

.Errors {
    color: #d00;
    font-weight: bold;
}

.Success {
    color: #080;
    font-weight: bold;
}